import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';

import { GetProperty } from '../Api/Fetchs/getPostProperty';
import { DataProperty } from './Saga';

interface ITopic {
	id: number;
	name: string;
	order: number;
	visible: boolean;
	url_name: string;
}
[];

interface IPostState {
	data: DataProperty;
	Loading: boolean;
	Error: boolean;
	topicState?: ITopic[];
}
export function* getPostsPropertySaga(
	action: ReturnType<typeof getProperty>
): any {
	try {
		yield put(getPostsPropertyLoading());
		const response = yield call(GetProperty);
		const payload = yield call([response, 'json']);
		yield put(getPostsPropertySuccess(payload));
	} catch (error) {
		yield put(getPostsPropertyError(error));
	}
}

const initialState: IPostState = {
	data: {},
	Loading: true,
	Error: false,
	topicState: []
};

const getPostProperty = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsPropertyLoading: state => {
			state.Loading = true;
		},
		getPostsPropertySuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsPropertyError: (state, action) => {
			state.Error = action.payload;
		},
		SetTopic: state => {
			state.topicState = state.data.topics
				?.filter(el => el.visible === true)
				.sort((a, b) => a.order - b.order);
		}
	}
});

export const GET_PROPERTY = 'article/getPropertyy';

export const getProperty = createAction(GET_PROPERTY);

export const {
	getPostsPropertyLoading,
	getPostsPropertySuccess,
	getPostsPropertyError,
	SetTopic
} = getPostProperty.actions;
export default getPostProperty.reducer;
