import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetStory } from '../Api/Fetchs/getPostStoryArticle';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
	filter: Array<DataArticle>;
}
export function* getPostsSagaStory(
	action: ReturnType<typeof getStoryPosts>
): any {
	try {
		yield put(getPostsStotyLoading());
		const params = action.payload || {};
		const response = yield call(GetStory, params);
		const payload = yield call([response, 'json']);
		yield put(getPostsStotySuccess(payload));
	} catch (error) {
		yield put(getPostsStotyError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false,
	filter: []
};

const getPostStoryArticle = createSlice({
	name: 'postsstory',
	initialState,
	reducers: {
		getPostsStotyLoading: state => {
			state.Loading = true;
		},
		getPostsStotySuccess: (state, action) => {
			state.data.push(...action.payload);
			// state.data = action.payload;
			state.Loading = false;
		},
		getPostsStotyError: (state, action) => {
			state.Error = action.payload;
		},
		ClearAllStoryId: state => {
			state.data = [];
			state.Loading = true;
		}
	}
});

export const GET_POSTS_STORY = 'article/getPostsStory1';

export const getStoryPosts = createAction<{
	topic?: any;
	whiteList: any;
	filter_ids?: any;
	pages: any;
}>(GET_POSTS_STORY);

export const {
	getPostsStotySuccess,
	getPostsStotyLoading,
	getPostsStotyError,
	ClearAllStoryId
} = getPostStoryArticle.actions;
export default getPostStoryArticle.reducer;
