'use client';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from '@/Redux/Slices/FilterSlice/states';

//--------------------------------------------------------------
export const FilterState = createSlice({
	name: 'FilterState',
	initialState,
	reducers: {
		SearchingPub(state, action) {
			state.SearchFilterArr = action.payload.AllArr.filter((obj: any) =>
				obj.name.toLowerCase().includes(action.payload.value.toLowerCase())
			);
		}
	}
});

export default FilterState.reducer;
