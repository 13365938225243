import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';

import { DataArticle } from './Saga';
import { getPostSearchFetch } from '../Api/Fetchs/getPostSearch';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	setings: number;
	Error: boolean;
}

export function* getPostSearchSaga(action: ReturnType<typeof getSearch>): any {
	try {
		yield put(getSearchLoading());
		const params = action.payload || {};
		const response = yield call(getPostSearchFetch, params);
		const payload = yield call([response, 'json']);
		yield put(getSearchSuccess(payload));
	} catch (error) {
		yield put(getSearchError(error));
	}
}

const initialState: IPostState = {
	data: [],
	setings: NaN,
	Loading: true,
	Error: false
};

const getPostSearchSlice = createSlice({
	name: 'getPostSearch',
	initialState,
	reducers: {
		getSearchLoading: state => {
			state.Loading = true;
		},
		getSearchSuccess: (state, action) => {
			state.setings = action.payload.count;
			state.data.push(...action.payload.articles);
			state.Loading = false;
		},
		getSearchError: (state, action) => {
			state.Error = action.payload;
		},
		clearAll: state => {
			state.data = [];
			state.setings = 0;
		}
	}
});

export const GET_SEARCH = 'post/getPostSearch';

export const getSearch = createAction<{
	pages: number;
	offset: number;
	value: string;
	order: string;
	filters: string;
}>(GET_SEARCH);

export const { getSearchLoading, getSearchSuccess, getSearchError, clearAll } =
	getPostSearchSlice.actions;
export default getPostSearchSlice.reducer;
