import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetSimiliarArticles } from '../Api/Fetchs/getPostSimilarArticles';
import type { DataArticle } from './Saga';
interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}
export function* getPostsSimiliarArticlesSaga(
	action: ReturnType<typeof getSimiliarArticles>
): any {
	try {
		yield put(getPostsSimiliarArticlesLoading());
		const params = action.payload || {};
		const response = yield call(GetSimiliarArticles, params);
		const payload = yield call([response, 'json']);
		yield put(getPostsSimiliarArticlesSuccess(payload));
	} catch (error) {
		yield put(getPostsSimiliarArticlesError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostSimiliarArticle = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsSimiliarArticlesLoading: state => {
			state.Loading = true;
		},
		getPostsSimiliarArticlesSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsSimiliarArticlesError: (state, action) => {
			state.Error = action.payload;
		},
		restData: state => {
			state.data = [];
		}
	}
});

export const GET_SIMILARARTICLES = 'article/getSimiliarArticles';

export const getSimiliarArticles = createAction<{ storyId?: any }>(
	GET_SIMILARARTICLES
);

export const {
	getPostsSimiliarArticlesSuccess,
	getPostsSimiliarArticlesLoading,
	getPostsSimiliarArticlesError,
	restData
} = getPostSimiliarArticle.actions;
export default getPostSimiliarArticle.reducer;
