export const GetStory = ({ whiteList, pages, filter_ids }: any) => {
	return fetch('/ApiNew?action=articles', {
		method: 'POST',
		body: JSON.stringify({
			block_id: 84683,
			count: pages,
			filter_ids: filter_ids,
			fields: 4194303,
			image_width: 360,
			image_height: 200,
			filter_publishers: whiteList,
			ctx: 'STORIES',
			sort_type: 'RANK_SUM'
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
