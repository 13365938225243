import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './states';

//--------------------------------------------------------------
export const SourseSlice = createSlice({
	name: 'SourseSlice',
	initialState,
	reducers: {
		// чёт не работает

		SetAllSourseId(state, action) {
			if (
				state.WhiteSourses.length === 0 ||
				state.WhiteSourses.every(item => action?.payload?.includes(item.id))
			) {
				state.WhiteSourses = action?.payload?.map((el: any) => el.id);
			}
		},
		deliteBlackListInWhiteList(state) {
			state?.WhiteSourses?.map((elW, ind) => {
				state.BllackSourse?.map(elB => {
					if (elW === elB.id) {
						state.WhiteSourses.splice(ind, 1);
					}
				});
			});
		},

		addWhiteList(state, action) {
			if (!state.WhiteSourses?.includes(action.payload.id)) {
				state.WhiteSourses?.unshift(action.payload.id);
			}
		},
		SetAllBllackSourse(state, action) {
			if (!state.BllackSourse?.includes(action.payload)) {
				state.BllackSourse.push(action.payload);
			}
		},

		DeleteAllBllackSourse(state, action) {
			state.BllackSourse?.map((el, ind) => {
				if (el.id === action.payload.id) {
					state.BllackSourse.splice(ind, 1);
				}
			});
		},
		AddOffToggle(state, action) {
			if (!state.ArrOffToggle.includes(action.payload.id)) {
				state.ArrOffToggle.push(action.payload.id);
			}
		},
		DeleteOffToggle(state, action) {
			state.ArrOffToggle.map((el, ind) => {
				if (el === action.payload.id) {
					state.ArrOffToggle.splice(ind, 1);
				}
			});
		},

		DeliteAll(state) {
			state.OffAllToggle = true;
			state.ArrOffToggle = [];
			state.BllackSourse = [];
		}
	}
});

export default SourseSlice.reducer;
