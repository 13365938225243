import { ValidateUrl } from '@/Hooks/fetcher';

export const GetProperty = async () => {
	return await fetch(
		`${
			ValidateUrl()
				? '/api?action=properties'
				: 'https://smi2.ru/newdata/jsapi?action=properties'
		}`,
		{
			method: 'POST',
			mode: 'cors',
			cache: 'force-cache',
			next: { revalidate: 300 },
			body: JSON.stringify({
				block_id: 84683,
				tags_type: 'MAIN'
			}),
			headers: new Headers({ 'content-type': 'application/json' })
			// next: { revalidate: 18000 }
		}
	);
};
