import { ValidateUrl } from '@/Hooks/fetcher';

export const GetTags = async ({
	TagsId,
	pages,
	whiteList,
	filter_ids
}: any) => {
	return await fetch(
		`${
			ValidateUrl()
				? '/api?action=articles'
				: 'https://smi2.ru/newdata/jsapi?action=articles'
		}`,
		{
			method: 'POST',
			mode: 'cors',
			cache: 'force-cache',
			next: { revalidate: 1 },
			body: JSON.stringify({
				// action: 'articles',
				block_id: 84683,
				count: pages,
				fields: 4194303,
				image_width: 360,
				image_height: 200,
				tag_id: TagsId,
				filter_publishers: whiteList,
				ctx: 'STORIES',
				filter_ids
			}),
			headers: new Headers({ 'content-type': 'application/json' })
		}
	);
};
