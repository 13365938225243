import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '@/Redux/Slices/NewsViewSlice/states';
//--------------------------------------------------------------

export const NewsViewSlice = createSlice({
	name: 'NewsView',
	initialState,
	reducers: {
		NewsViewChangeToCard(state) {
			state.newsViewBollean = true;
		},
		NewsViewChangeToLine(state) {
			state.newsViewBollean = false;
		},
		AddRequest(state, action) {
			state.Request = action.payload;
		},
	},
});

export default NewsViewSlice.reducer;
