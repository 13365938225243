import { ValidateUrl } from '@/Hooks/fetcher';

export const GetComments = async (params: any) => {
	return await fetch(
		`${
			ValidateUrl()
				? '/api?action=article_details'
				: 'https://smi2.ru/newdata/jsapi?action=article_details'
		}`,
		{
			mode: 'cors',
			method: 'POST',
			cache: 'force-cache',
			next: { revalidate: 60 },
			body: JSON.stringify({
				block_id: 84683,
				article_id: params,
				fields: 4194303,
				image_height: 150,
				image_width: 250
			}),
			headers: new Headers({ 'content-type': 'application/json' })
		}
	);
};
