import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetPostSimular } from '../Api/Fetchs/getPostSimular';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}

export function* getPostSimularSaga(
	action: ReturnType<typeof getSimular>
): any {
	try {
		yield put(getPostSimularLoading());
		const params = action.payload || {};
		const response = yield call(GetPostSimular, params);
		const payload = yield call([response, 'json']);
		yield put(getPostSimularSuccess(payload));
	} catch (error) {
		yield put(getPostSimularError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostSimular = createSlice({
	name: 'Simular',
	initialState,
	reducers: {
		getPostSimularLoading: state => {
			state.Loading = true;
		},
		getPostSimularSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPostSimularError: (state, action) => {
			state.Error = action.payload;
		},
		restDataSimular: state => {
			state.data = [];
		}
	}
});

export const GET_SIMULAR = 'topic/Simular';

export const getSimular = createAction<{
	storyId?: any;
}>(GET_SIMULAR);

export const {
	getPostSimularLoading,
	getPostSimularSuccess,
	getPostSimularError,
	restDataSimular
} = getPostSimular.actions;
export default getPostSimular.reducer;
