import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';

import { GetLocalNews } from '../Api/Fetchs/getPostLocalNews';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}
export function* getPostsLocalNewsSaga(
	action: ReturnType<typeof getNewsLocal>
): any {
	try {
		yield put(getLocalNewsLoading());
		const params = action.payload || [];
		const response = yield call(GetLocalNews, params);
		const payload = yield call([response, 'json']);
		yield put(getLocalNewsSuccess(payload));
	} catch (error) {
		yield put(getPostsNewsLocalError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostNewsLocal = createSlice({
	name: 'posts/localnews',
	initialState,
	reducers: {
		getLocalNewsLoading: state => {
			state.Loading = true;
		},
		getLocalNewsSuccess: (state, action) => {
			state.data.push(...action.payload);
			state.Loading = false;
		},
		getPostsNewsLocalError: (state, action) => {
			state.Error = action.payload;
		},
		ClearAll: state => {
			state.data = [];
			state.Loading = true;
		}
	}
});

export const GET_LOCALNEWS = 'article/getLocalNews';

export const getNewsLocal = createAction<{
	pages?: any;
	loc?: any;
	whiteList?: any;
	filter_ids?: any;
}>(GET_LOCALNEWS);

export const {
	getLocalNewsLoading,
	getLocalNewsSuccess,
	getPostsNewsLocalError,
	ClearAll
} = getPostNewsLocal.actions;
export default getPostNewsLocal.reducer;
