import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetPublisher } from '../Api/Fetchs/getPostsPublisher';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}

export function* getPostsPublisherSaga(
	action: ReturnType<typeof getPostsPub>
): any {
	try {
		yield put(getPublisherLoading());
		const params = action.payload || {};
		const response = yield call(GetPublisher, params);
		const payload = yield call([response, 'json']);
		yield put(getPublisherSuccess(payload));
	} catch (error) {
		yield put(getPublisherError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostArticlePublisher = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPublisherLoading: state => {
			state.Loading = true;
		},
		getPublisherSuccess: (state, action) => {
			state.data.push(...action.payload);
			state.Loading = false;
		},
		getPublisherError: (state, action) => {
			state.Error = action.payload;
		},
		restData: state => {
			state.data = [];
		}
	}
});

export const GET_POSTPUB = 'publisher/getPostsPublisher';
export const getPostsPub = createAction<{
	publisherId: number;
	pages?: number;
	// whiteList: any;
	filter_ids?: any;
}>(GET_POSTPUB);

export const {
	getPublisherSuccess,
	getPublisherLoading,
	getPublisherError,
	restData
} = getPostArticlePublisher.actions;
export default getPostArticlePublisher.reducer;
