import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';

import { DataArticle } from './Saga';
import { GetostFullArticlFetch } from '../Api/Fetchs/getPostFullArticle';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}
export function* getFullArticleSaga(
	action: ReturnType<typeof getFullArticle>
): any {
	try {
		yield put(getPostsFullArticleLoading());
		const params = action.payload || {};
		const response = yield call(GetostFullArticlFetch, params);
		const payload = yield call([response, 'json']);
		yield put(getPostsFullArticleSuccess(payload));
	} catch (error) {
		yield put(getPostsFullArticleError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostsFullArticle = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsFullArticleLoading: state => {
			state.Loading = true;
		},
		getPostsFullArticleSuccess: (state, action) => {
			// state.data.push(...action.payload);
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsFullArticleError: (state, action) => {
			state.Error = action.payload;
		}
	}
});

export const GET_POSTFULLARTICLE = 'article/getPostsNews';

export const getFullArticle = createAction<{
	params?: any;
}>(GET_POSTFULLARTICLE);

export const {
	getPostsFullArticleLoading,
	getPostsFullArticleSuccess,
	getPostsFullArticleError
} = getPostsFullArticle.actions;
export default getPostsFullArticle.reducer;
