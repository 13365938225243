import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetTags } from '../Api/Fetchs/getPostsTags';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}

export function* getPostTagsSaga(action: ReturnType<typeof getTags>): any {
	try {
		yield put(getTagsLoading());
		const params = action.payload || {};
		const response = yield call(GetTags, params);
		const payload = yield call([response, 'json']);
		yield put(getTagsSuccess(payload));
	} catch (error) {
		yield put(getTagsError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostsTagsSaga = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getTagsLoading: state => {
			state.Loading = true;
		},
		getTagsSuccess: (state, action) => {
			state.data.push(...action.payload);
			state.Loading = false;
		},
		getTagsError: (state, action) => {
			state.Error = action.payload;
		},
		restData: state => {
			state.data = [];
			state.Loading = true;
		}
	}
});

export const GET_POSTTAGS = 'tags/getPostsTags';

export const getTags = createAction<{
	TagsId: number;
	pages?: number;
	whiteList: any;
	filter_ids?: any;
}>(GET_POSTTAGS);

export const { getTagsSuccess, getTagsError, getTagsLoading, restData } =
	getPostsTagsSaga.actions;
export default getPostsTagsSaga.reducer;
