'use client';

import { useAppSelector } from '@/Hooks/redux';
import { getProperty } from '@/Redux/saga/getPostProperty';
import { use, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SetTopic } from '@/Redux/saga/getPostProperty';
export const GetPropertyss = () => {
	const Property = useAppSelector(state => state.getPostProperty);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getProperty());
	}, []);

	useEffect(() => {
		dispatch(SetTopic());
	}, [Property.data]);

	return <></>;
};
